import React from "react"
import Handwerk from "../components/Handwerk"

import Seo from "../components/Seo"
const Handwerkseite = () => {
  return (
    <>
      <Handwerk />
    </>
  )
}

export default Handwerkseite