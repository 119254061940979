import React from "react"

import Zoom from "react-reveal/Zoom"
// import heroImg from "../assets/images/hero.svg"
import { StaticImage } from "gatsby-plugin-image"

const Handwerker = () => {
  return (
    <div>
      <header class="p-10 min-h-screen flex items-center justify-center bg-teal-400 second">
        <div className="max-w-screen-xl mx-auto grid grid-cols-2 gap-8 lg:gap-24 md:items-center">
          <div className="col-span-2 md:col-span-1 flex flex-row space-x-2">
            <Zoom left>
              <StaticImage
                src="../assets/images/handwerker.png"
                alt="portfolio"
                className="hero-img-hoch"
                placeholder="blurred"
              />
            </Zoom>
          </div>
          <div className="col-span-2 md:col-span-1">
            <h2 className="text-2xl sm:text-3xl  lg:text-4xl text-MedienwerkstattDarkBlue font-medium mb-6">
              Sie verstehen Ihr Handwerk. Wir verstehen Internet. Zusammen
              können wir Ihr Geschäft digital entwickeln!
            </h2>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Handwerker
